
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import { lazy, useEffect, useState } from "react";
import useTranslation from "next-translate/useTranslation";
//Utils
import authConfig from "../../@core/config/auth";
import { get, track } from "../../handler";
import dynamic from "next/dynamic";
import { getSession, useSession } from "next-auth/react";
//Custom Components
const Layout = dynamic(() => import("../../@core/components/common/layout", {}).then((module) => module.Layout), {
    ssr: false,
});
const Banner = dynamic(() => import("../../@core/components/common/banner/index").then((module) => module.Banner), {
    ssr: false,
});
const TabSection = dynamic(() => import("../../@core/components/tabs/section").then((module) => module.TabSection), {
    ssr: false,
});
const BannerImage = lazy(() => import("../../@core/components/common/design/coloredBanner"));
const Courses = lazy(() => import("../../@core/components/pages/courses/list"));
const Logo = dynamic(() => import("../../@core/components/common/widgets/logo"), {
    ssr: false,
});
const SpinnerCustomize = dynamic(() => import("../../@core/components/common/widgets/spinner"), {
    ssr: false,
});
const Information = lazy(() => import("../../@core/components/common/widgets/information"));
import { InformationInterface } from "../../@core/components/common/widgets/information";
import { useRouter } from "next/router";
import ItemModal from "../../@core/components/common/widgets/modal/item.modal";
// import WaitingRoom from "../../@core/components/pages/webinar/waiting.webinar";
const WebinarItem = lazy(() => import("../../@core/components/pages/webinar/item.webinar"));
const Box = lazy(() => import("@mui/material/Box"));
const Button = lazy(() => import("@mui/material/Button"));
const Container = lazy(() => import("@mui/material/Container"));
const Grid = lazy(() => import("@mui/material/Grid"));
const Typography = lazy(() => import("@mui/material/Typography"));
export default function Home(props: any) {
    const router = useRouter();
    const { t, lang } = useTranslation();
    const { data: session } = useSession();
    const [banner, setBanner] = useState(null);
    const [webinar, setWebinar] = useState([]);
    const [popup, setPopup] = useState(null);
    const [info, setInfo] = useState({} as InformationInterface);
    const [loading, setLoading] = useState(true);
    const init = async () => {
        try {
            setInfo(props?.information);
            setBanner(props?.banner);
            setWebinar(props?.webinar);
            setPopup(props?.popup);
            setLoading(false);
        }
        catch (e) {
            setLoading(false);
            console.log(e);
        }
    };
    useEffect(() => {
        track("page visit", {
            name: "home",
            email: session?.user?.email,
            language: lang,
            type: "page visit",
        });
    }, []);
    useEffect(() => {
        init();
    }, [lang]);
    return (<Layout page={"home"} head={""} loading={!loading} route={"/"} title={""}>
      <ItemModal item={props.popup} status={props.popup ? true : false}/>

      {loading ? (<>
          <SpinnerCustomize loading={!loading} height={30} width={4}>
            <Box mb={2}>
              <Logo name={require("/static/images/logo.png")} width={200} height={30} active={"home"} onClick={() => { }}/>
            </Box>
          </SpinnerCustomize>
        </>) : (<>
          <BannerImage sx={{
                minHeight: { xl: "100% ", md: "100%", xs: "100%" },
            }}>
            <Grid className="main" sx={{
                mb: { md: 10, xs: 1 },
                width: "100%",
            }}>
              <Banner banner={banner} setLoading={setLoading}/>
            </Grid>
          </BannerImage>
          <Grid sx={{
                position: "relative",
                width: "100%",
            }} display={"flex"} justifyContent={"center"} alignItems={"center"}>
            <Information info={info}/>
          </Grid>

          <TabSection />
          <Courses />

          {webinar?.length > 0 ? (<Grid mt={3} mb={3} display={"flex"} justifyContent={"center"} alignItems={"center"}>
              <Container sx={{
                    maxWidth: { md: "xl", xs: "100%" },
                    width: "100%",
                    p: { md: 2, xs: 1 },
                    pt: { md: 5, xs: 3 },
                }} disableGutters>
                {/* <WaitingRoom webinar={webinar[0]}/> */}
                <Grid sx={{
                    position: "relative",
                    flexDirection: { md: "row", xs: "column" },
                    justifyContent: { md: "space-between", xs: "center" },
                    alignItems: { md: "center", xs: "center" },
                    width: 1,
                }} display={"flex"}>
                  <Typography variant="h4" className="font-bold" color={"primary"} mt={2} mb={3}>
                    {t("page:upcoming")} {t("page:webinar")}
                  </Typography>
                  <Grid sx={{
                    display: { md: "flex", xs: "flex" },
                    alignSelf: { md: "center", xs: "flex-end" },
                    mt: { md: 0, xs: 3 },
                }}>
                    <Button color={"primary"} className="font-bold" variant="outlined" onClick={() => {
                    router.push("/webinar/upcoming", undefined, {
                        shallow: true,
                    });
                }} sx={{
                    borderRadius: 10,
                    border: 1.5,
                    width: 150,
                    textTransform: "capitalize",
                }}>
                      {t("common:all")} {t("common:webinars")}
                    </Button>
                  </Grid>
                </Grid>

                <Grid sx={{
                    position: "relative",
                    flexDirection: { xs: "column" },
                    justifyContent: { md: "space-between", xs: "center" },
                    alignItems: { md: "flex-start", xs: "center" },
                }} display={"flex"}>
                  <Grid container sx={{
                    maxWidth: { md: "xl", xs: "100%" },
                    width: "100%",
                    p: { md: 2, xs: 1 },
                    pt: { md: 5, xs: 3 },
                }} display={"flex"} justifyContent={"center"} alignSelf={"center"} spacing={1}>
                    {webinar.map((item, key) => {
                    return (<Grid key={key} item md={6} display={"flex"} justifyContent={"center"} alignSelf={"center"} flexDirection={"column"} alignItems={"center"}>
                          <WebinarItem item={item} sx={{ background: "#DCEEEE" }} maxWidth={{
                            md: "100%",
                            sm: "100%",
                            xs: "100%",
                        }}/>
                        </Grid>);
                })}
                  </Grid>
                </Grid>
              </Container>
            </Grid>) : null}
        </>)}
    </Layout>);
}
async function getServerSideProps(ctx: any) {
    const locale = ctx.locale;
    const session = await getSession({ req: ctx.req });
    ctx.res.setHeader("Cache-Control", "public, s-maxage=10, stale-while-revalidate=59");
    let data = await get(authConfig.home, session?.user?.token ?? null, locale);
    if (data && data.status_code == 200) {
        return {
            props: {
                information: data?.data?.information,
                banner: data?.data?.banner,
                webinar: data?.data?.webinar,
                popup: data?.data?.popup,
            },
        };
    }
    return {
        props: {},
    };
}
Home.auth = false;

    async function __Next_Translate__getServerSideProps__194baffb3b3__(ctx) {
      const res = await getServerSideProps(ctx)
      return {
        
        ...res,
        props: {
          ...(res.props || {}),
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/index',
            loaderName: 'getServerSideProps',
            loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
          }))
        }
      }
    }
    export { __Next_Translate__getServerSideProps__194baffb3b3__ as getServerSideProps }
  